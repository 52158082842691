import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Avatar,
  Button,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core'

import { HEADER_HEIGHT } from '../utils/core'
import Menu from './menu'
import Window from '../utils/window'
import Colors from '../utils/colors'

const headerStyle = makeStyles(theme => ({
  appBar: {
    color: 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(8px)',
    boxShadow: 'none',
    height: HEADER_HEIGHT,
    position: 'sticky', // Needs to be either sticky or initial
  },
  branding: {
    height: HEADER_HEIGHT,
  },
  logo: {
    height: HEADER_HEIGHT,
    width: HEADER_HEIGHT,
    marginRight: theme.spacing(1),
    '& img': {
      objectFit: 'contain',
    },
  },
  title: {
    height: HEADER_HEIGHT,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& p': {
      fontFamily: 'Rubik',
      textAlign: 'left',
      color: Colors.get('grey', 600),
    },
  },
  titleSub: {
    fontSize: '1em',
    transform: 'rotate(-5deg)',
    textTransform: 'initial',
  },
  titleMain: {
    fontSize: '2em',
    paddingLeft: '.05em',
  },
  buttonContainer: {
    marginRight: theme.spacing(1),
    flexGrow: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const Header = React.forwardRef((props, ref) => {
  const classes = headerStyle()

  const desktopView = () => {
    return (
      <AppBar className={classes.appBar} ref={ref}>
        <Toolbar className={classes.icon}>
          <Button
            className={classes.branding}
            onClick={() => Window.navigate('/home')}
          >
            <Avatar
              alt="Kawaii Axoltol"
              src={'https://i.imgur.com/O6vTw1H.png'}
              className={classes.logo}
            />
            <Grid item className={classes.title}>
              <Typography className={classes.titleSub}>Made to</Typography>
              <Typography className={classes.titleMain}>UPLIFT</Typography>
            </Grid>
          </Button>
          <Grid>
            <Menu />
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }

  return desktopView()
})

Header.propTypes = {}
Header.defaultProps = {}

export default Header

export default class Document {
  static createElement(...type) {
    if (typeof document === 'undefined') return {}
    return document.createElement(...type)
  }

  static get(...query) {
    if (typeof document === 'undefined') return {}
    return document.querySelector(...query)
  }

  static head(type, obj) {
    if (typeof document === 'undefined') return
    if (type === 'append') document.head.appendChild(obj)
    else if (type === 'remove') document.head.removeChild(obj)
  }
}

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Colors from '../utils/colors'
import Window from '../utils/window'
import google from '../backend/google'
import { CustomIcon, IconWrapper } from '../components/icon'
import IconButton from './icon_button'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(_theme => ({
  root: {},
}))(MenuItem)

const useStyle = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row',
  },
}))

export default function HeaderButtons({ invertBackground }) {
  const classes = useStyle()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function menu() {
    return [
      /* {
        label: 'Start Fundraising',
        icon: {
          src: 'money',
          color: Colors.get('green'),
        },
        onClick: () => {},
      },
      {
        label: 'Login',
        icon: {
          src: 'sign_out',
        },
        onClick: () => {},
      }, */
      {
        label: 'Campaign',
        icon: {
          src: 'campaign',
        },
        onClick: () => {
          Window.navigate('/campaign/?id=1')
        },
      },
      {
        label: 'About Us',
        icon: {
          src: 'person',
        },
        onClick: () => {
          Window.navigate('/about')
        },
      },
      // i can link to section on the home page
      /* {
        label: 'How We Work',
        icon: {
          src: 'settings',
        },
        onClick: () => {},
      },
      {
        label: 'Get Started',
        icon: {
          src: 'edit',
        },
        onClick: () => {},
      },
      {
        label: 'Our Impact',
        icon: {
          src: 'heart',
        },
        onClick: () => {},
      }, */
      /* {
        label: 'Frequently Asked Questions',
        icon: {
          src: 'help',
        },
        onClick: () => {},
      }, */
    ]
  }

  return (
    <Grid item className={classes.container}>
      <IconButton
        icon={new IconWrapper('menu')}
        tooltip="Your Profile"
        style={invertBackground ? { backgroundColor: 'white' } : null}
        onClick={handleClick}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_.map(menu(), btn => {
          return (
            <StyledMenuItem
              onClick={() => {
                btn.onClick()
                handleClose()
              }}
              key={btn.label}
            >
              <ListItemIcon>
                <CustomIcon
                  icon={new IconWrapper(btn.icon.src)}
                  color={btn.icon.color}
                />
              </ListItemIcon>
              <ListItemText>{btn.label}</ListItemText>
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </Grid>
  )
}

HeaderButtons.propTypes = {
  invertBackground: PropTypes.bool,
}
HeaderButtons.defaultProps = {
  invertBackground: false,
}

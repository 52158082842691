import React from 'react'
import { local, subscribe } from 'brownies'

import { makeStyles } from '@material-ui/core/styles'
import { Badge, Grid } from '@material-ui/core'

import Cart from '../backend/cart'
import { CustomIcon, IconWrapper } from './icon'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
    border: '1px solid lightgray',
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
    cursor: 'pointer',
    padding: '12px 36px 6px 12px',
    position: 'fixed',
    right: 0,
    top: theme.spacing(15),
    width: 50,
    zIndex: 100,
  },
}))

export default function FloatingCartButton() {
  const classes = useStyles()
  const [items, setItems] = React.useState(0)

  React.useEffect(() => {
    setItems(Cart.size())
    subscribe(local, 'cart', () => {
      setItems(Cart.size())
    })
  }, [])

  function redirectToCart() {
    Cart.clear()
  }

  return (
    <Grid container className={classes.container} onClick={redirectToCart}>
      <Badge badgeContent={items} color="error">
        <CustomIcon icon={new IconWrapper('cart_empty')} />
      </Badge>
    </Grid>
  )
}

FloatingCartButton.propTypes = {}
FloatingCartButton.defaultProps = {}

import { makeStyles } from '@material-ui/core/styles'

const pageStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    overflowX: 'hidden',
  },
  content: {
    flex: 1,
    width: '100%',
    backgroundColor: '#EBF5F8',
    //paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    minHeight: '2.5em',
  },
}))

export default pageStyle

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import Colors from '../utils/colors'

import {
  Add,
  Apps,
  AddShoppingCart,
  AttachMoney,
  CheckCircle,
  Dashboard,
  DirectionsRun,
  Edit,
  Email,
  EmojiObjectsOutlined,
  Error,
  ExitToApp,
  Facebook,
  Favorite,
  FavoriteBorder,
  FlashOn,
  Grain,
  GroupAdd,
  Help,
  Instagram,
  Launch,
  Link,
  LocalShipping,
  Lock,
  Looks,
  MonetizationOn,
  Menu,
  NotificationsActive,
  Person,
  People,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Remove,
  Settings,
  ShoppingCartOutlined,
  Timelapse,
  ThumbUp,
  Twitter,
  VerifiedUser,
  Warning,
  YouTube,
  Receipt,
  Filter2,
  Filter3,
  Filter1,
} from '@material-ui/icons'

const icons = {
  mui: {
    1: Filter1,
    2: Filter2,
    3: Filter3,
    campaign: Apps,
    cart_add: AddShoppingCart,
    cart_empty: ShoppingCartOutlined,
    check_circle: CheckCircle,
    dashboard: Dashboard,
    dollar: AttachMoney,
    edit: Edit,
    email: Email,
    error: Error,
    facebook: Facebook,
    heart: Favorite,
    heart_outline: FavoriteBorder,
    lightening: FlashOn,
    instagram: Instagram,
    help: Help,
    groupadd: GroupAdd,
    group: People,
    launch: Launch,
    lightbulb: EmojiObjectsOutlined,
    link: Link,
    lock: Lock,
    minus: Remove,
    menu: Menu,
    money: MonetizationOn,
    newPeople: GroupAdd,
    notify: NotificationsActive,
    person: Person,
    plus: Add,
    radio: RadioButtonUnchecked,
    radio_checked: RadioButtonChecked,
    rainbow: Looks,
    receipt: Receipt,
    settings: Settings,
    shipping: LocalShipping,
    sign_out: ExitToApp,
    sticker: Grain,
    timelapse: Timelapse,
    thumbup: ThumbUp,
    twitter: Twitter,
    verified: VerifiedUser,
    warning: Warning,
    youth: DirectionsRun,
    youtube: YouTube,
  },
}

export class IconWrapper {
  constructor(name, library) {
    this.name = name
    this.library = library || 'mui' // default to MaterialUI
  }

  get component() {
    const icon = _.get(icons, [this.library, this.name])
    if (icon === null)
      throw Error(`Icon not accounted for: ${this.library}-${this.name}`)
    return icon
  }
}

export function CustomIcon({ className, color, icon, size, style, tooltip }) {
  // By hue or explicit hex
  const parsedColor = Colors.get(color) || color

  const iconComponent = React.createElement(icon.component, {
    style: _.assign(style || {}, {
      color: parsedColor,
    }),
    className: className,
    fontSize: size,
  })

  if (tooltip)
    return (
      <Tooltip className={className} fontSize={size} title={tooltip}>
        {iconComponent}
      </Tooltip>
    )

  return iconComponent
}

CustomIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  tooltip: PropTypes.string,
}

CustomIcon.defaultProps = {
  color: 'blueGrey',
  style: null,
  tooltip: null,
}

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import { IconWrapper } from './icon'
import IconButton from './icon_button'
import Window from '../utils/window'
import Colors from '../utils/colors'

export default function SocialLinks({ className, links, oneColor }) {
  return (
    <Grid className={className}>
      {_.get(links, 'website') ? (
        <IconButton
          onClick={() => Window.newTab(links.website)}
          icon={new IconWrapper('link')}
          color={oneColor ? oneColor : 'indigo'}
          tooltip="Website"
        />
      ) : null}
      {_.get(links, 'facebook') ? (
        <IconButton
          onClick={() => Window.newTab(links.facebook)}
          icon={new IconWrapper('facebook')}
          color={oneColor ? oneColor : Colors.get('blue', 'A700')}
          tooltip="Facebook"
        />
      ) : null}
      {_.get(links, 'instagram') ? (
        <IconButton
          onClick={() => Window.newTab(links.instagram)}
          icon={new IconWrapper('instagram')}
          color={oneColor ? oneColor : Colors.get('pink', 'A400')}
          tooltip="Instagram"
        />
      ) : null}
      {_.get(links, 'twitter') ? (
        <IconButton
          onClick={() => Window.newTab(links.twitter)}
          icon={new IconWrapper('twitter')}
          color={oneColor ? oneColor : Colors.get('lightBlue', 'A400')}
          tooltip="Twitter"
        />
      ) : null}
      {_.get(links, 'youtube') ? (
        <IconButton
          onClick={() => Window.newTab(links.youtube)}
          icon={new IconWrapper('youtube')}
          color={oneColor ? oneColor : 'red'}
          tooltip="YouTube Channel"
        />
      ) : null}
      {_.get(links, 'email') ? (
        <a href={`mailto:${links.email}`} style={{ textDecoration: 'none' }}>
          <IconButton
            icon={new IconWrapper('email')}
            color={oneColor ? oneColor : 'green'}
            tooltip="Get in touch!"
          />
        </a>
      ) : null}
    </Grid>
  )
}

SocialLinks.propTypes = {
  className: PropTypes.string,
  links: PropTypes.object.isRequired,
}

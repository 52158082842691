import React from 'react'
import Helmet from 'react-helmet'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { Slide, useScrollTrigger } from '@material-ui/core'

import { APP_TITLE } from '../utils/core'
import Analytics from '../backend/analytics'
import FloatingCart from '../components/floating_cart'
import Footer from '../components/footer'
import Header from '../components/header'
import pageStyle from '../styles/page'
import Window from '../utils/window'

function Page({ content, customCSS, enqueueSnackbar, showCart, title }) {
  const classes = pageStyle()
  const trigger = useScrollTrigger()

  React.useEffect(() => {
    Window.alertFn = enqueueSnackbar
    Analytics.ViewPage()
  }, [enqueueSnackbar])

  return (
    <div className={classes.container}>
      <Helmet>
        <style>{customCSS}</style>
        <title>{title}</title>
      </Helmet>

      {/** HEADER */}
      <Slide appear={false} in={!trigger} direction="down">
        <Header />
      </Slide>

      {/** PAGE */}
      <div className={classes.content}>{content}</div>

      {showCart ? <FloatingCart /> : null}

      {/** FOOTER */}
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}

Page.propTypes = {
  content: PropTypes.element,
  customCSS: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
  requiresAuthentication: PropTypes.bool,
  showCart: PropTypes.bool,
  title: PropTypes.string,
}

Page.defaultProps = {
  content: null,
  customCSS: null,
  requiresAuthentication: false,
  showCart: false,
  title: APP_TITLE,
}

export default withSnackbar(Page)

import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Colors from '../utils/colors'
import { CustomIcon } from './icon'

export default function CustomIconButton({
  className,
  color,
  disabled,
  icon,
  onClick,
  size,
  style,
  tooltip,
}) {
  function renderIconButton() {
    return (
      <IconButton
        className={className}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        <CustomIcon icon={icon} color={color} size={size} />
      </IconButton>
    )
  }

  return tooltip ? (
    <Tooltip title={tooltip}>{renderIconButton()}</Tooltip>
  ) : (
    renderIconButton()
  )
}

CustomIconButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
}

CustomIconButton.defaultProps = {
  color: Colors.get('blueGrey', 700),
  onClick: () => {},
  size: 'default',
  style: null,
  tooltip: null,
}

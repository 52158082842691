import _ from 'lodash'

import Document from '../utils/document'
import Logger from '../utils/logger'
import Window from '../utils/window'

let attempts = 0
const attemptLimit = 3

const CREDENTIALS = {
  auth: null,
  apiKey: () => 'AIzaSyCmKRY3P5lwnbh1NTF_v_TypYozq-n6H6c',
  clientId: () => {
    return '929479375132-itt9bumirflev6hlr33g75oo80mpu7km.apps.googleusercontent.com'
  },
  scopes: () => 'profile',
  user_token: null,
}

class GoogleAPI {
  constructor() {
    this.clean()
    this.getGapi()
  }

  /**
        Easy access fn to check if there was an error with an API call
    */
  checkForPermissionError = msg => {
    if (msg.error === 'popup_closed_by_user' || msg.error === 'access_denied') {
      Window.alert('You must sign in and grant us permission to continue', {
        variant: 'error',
      })
      return true
    }
    return false
  }

  /**
        Cleans all data
    */
  clean = () => {
    CREDENTIALS.auth = null
    CREDENTIALS.user_token = null
  }

  credentials = CREDENTIALS

  /**
        Makes sure that the app has access to Google.
        If Gapi is unavailable, attempt to manually load script again.
    */
  getGapi = callback => {
    // if (isTestMode) return

    // If API is already loaded, go to next step (initialize client)
    if (this.status().gapiExists) {
      Logger.measure('GOOG.INIT', 'Google API script received')
      const loadFn = Window.fn('gapi.load')
      return loadFn('client', () => this.initializeClient(callback))
    }

    // Else, try to manually load it since existing script tag failed
    Logger.measure('GOOG.INIT', 'Grabbing Google API script')
    attempts++

    const script = Document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.onerror = () => {
      if (attempts >= 3) Window.alert(`Failed to load Google services`)
    }
    Document.head('append', script)

    // Try this function again to see if there were any changes
    if (attempts < attemptLimit) _.delay(this.getGapi, 1500, callback)
  }

  /**
        Easy-access fn for DB functions to auto-include user-token.
    */
  getRequestBody = (data = {}) => {
    const formattedData = _.cloneDeep(data)
    formattedData.user_token = this.getUserToken()
    return formattedData
  }

  /**
        Easy-access fn to get profile information about logged-in user.
    */
  getUser = () => {
    const user = CREDENTIALS.auth.currentUser.get().getBasicProfile()

    const profile = {}
    profile.email = user.getEmail()
    profile.name = user.getName()
    profile.picture = user.getImageUrl()

    return profile
  }

  /**
        Easy-access fn for getting a user's ID token for backend verfication.
    */
  getUserToken = () => {
    const thenTryAgain = () => this.getUserToken()
    if (!this.status().isSignedIn) return this.signIn(thenTryAgain)

    CREDENTIALS.user_token = CREDENTIALS.auth.currentUser
      .get()
      .getAuthResponse().id_token
    return CREDENTIALS.user_token
  }

  /**
        Connects to Google Services using API key
        @param {callback} Callback for once all services have been initialized
    */
  initializeClient = callback => {
    // API is still loading
    if (!this.status().gapiClientExists) {
      Logger.measure(
        'GOOG.INIT',
        'Waiting for Google Client API to be loaded in app'
      )
      _.delay(this.initializeClient, 500, callback)
      return
    }

    // Initialization already happened
    if (this.status().gapiClientInitialized) return

    Logger.measure('GOOG.INIT', 'Initializing Google Client API')

    const initializeFn = Window.fn('gapi.client.init')
    initializeFn({
      apiKey: CREDENTIALS.apiKey(),
      clientId: CREDENTIALS.clientId(),
      scope: CREDENTIALS.scopes(),
      fetchBasicProfile: true,
    }).then(() => {
      // Get authorization for user
      const getAuthInstance = Window.fn('gapi.auth2.getAuthInstance')
      CREDENTIALS.auth = getAuthInstance()

      // Create easy access fn for user authorization
      CREDENTIALS.auth.accessToken = () =>
        CREDENTIALS.auth.currentUser.get().getAuthResponse().access_token

      // Save user token
      this.getUserToken()

      // Execute callback
      if (_.isFunction(callback)) callback()
    })
  }

  /**
        Easy-access func
    */
  isSignedIn = () => {
    return !!this.status().isSignedIn
  }

  /**
        Log a user out and reset relevant variables
    */
  reset = callback => {
    if (this.status().isSignedIn) {
      CREDENTIALS.auth.signOut()
    }

    this.clean()

    if (_.isFunction(callback)) callback()
  }

  /**
        Easy access function to sign a user in through Google and ensure we have permissions.
    */
  signIn = (callback, silent = false) => {
    const thenTryAgain = () => this.signIn(callback)
    if (!this.status().gapiClientInitialized)
      return this.initializeClient(thenTryAgain)

    const initiateSession = () => {
      this.getUserToken()

      if (_.isFunction(callback)) callback()
    }

    // Prevent duplicate sign-in
    if (this.status().isSignedIn) return initiateSession()

    const prompt = {
      prompt: silent ? 'none' : 'consent',
    } // TODO Doesn't seem to actually be silent

    CREDENTIALS.auth
      .signIn(prompt)
      .then(initiateSession)
      .catch(this.checkForPermissionError)
  }

  signOut = () => {
    this.reset(() => Window.navigate('/home'))
  }

  /**
        Returns a one-stop object with any/all relevant information to know the status of this API.
    */
  status = () => {
    let stages = {}

    if (Window.get('gapi.load')) stages.gapiExists = true

    if (Window.get('gapi.client.init')) stages.gapiClientExists = true

    if (CREDENTIALS.auth) stages.gapiClientInitialized = true

    if (
      _.has(CREDENTIALS.auth, 'isSignedIn') &&
      CREDENTIALS.auth.isSignedIn.get()
    )
      stages.isSignedIn = true

    return stages
  }
}
const google = new GoogleAPI()
export default google

import _ from 'lodash'
import { local } from 'brownies'
import moment from 'moment'

import Backend from './backend'
import REST from '../utils/rest'
import Analytics from './analytics'
import Window from '../utils/window'

export default class Cart {
  static addDonation(ID, amount, subscribe = false) {
    this.nullCheck()
    const newCart = local.cart

    _.set(newCart, ['campaigns', ID, 'donations', 'amount'], amount)
    _.set(newCart, ['campaigns', ID, 'donations', 'subscribe'], subscribe)

    local.cart = newCart
  }

  static addItem(ID, price, quantity = 1, subscribe = false) {
    this.nullCheck()
    const newCart = local.cart

    _.set(newCart, ['campaigns', ID, 'price'], price)
    _.set(newCart, ['campaigns', ID, 'quantity'], quantity)
    _.set(newCart, ['campaigns', ID, 'subscribe'], subscribe)

    local.cart = newCart
  }

  static checkout() {
    this.nullCheck()
    Analytics.InitiateCheckout({
      campaigns: _.keys(_.get(local, ['cart', 'campaigns'])),
      value: this.total(),
    })

    return REST.post('checkout/initiate', {
      cart: local.cart,
      uid: Analytics.userIdentifier(),
      url: Window.get(['location', 'href']),
      ds: moment().format('YYYY-MM-DD'),
    }).then(data => {
      const stripe = window.Stripe(Backend.stripeKey)
      stripe.redirectToCheckout({ sessionId: data.sessionID })
    })
  }

  static clear() {
    local.cart = {
      campaigns: {},
    }
  }

  static completePurchase(confirmation) {
    return REST.post(
      'checkout/complete',
      {
        confirmation,
        url: Window.get(['location', 'href']),
      },
      false
    ).then(data => {
      Analytics.Purchase({
        campaigns: [data.campaign],
        value: data.total,
        transactionID: data.transaction_id,
      })
      Cart.clear()
      return data
    })
  }

  static items() {
    this.nullCheck()
    return local.cart
  }

  static nullCheck() {
    if (local.cart === null) {
      Cart.clear()
    }
  }

  static size() {
    this.nullCheck()
    const campaign = _.sumBy(local.cart.campaigns, 'quantity')
    const donations = 0 // TODO _.size(local.cart.donations)
    return campaign + donations
  }

  static total() {
    this.nullCheck()

    return _.sum(
      _.map(local.cart.campaigns, obj => {
        return (
          _.get(obj, ['donations', 'amount'], 0) +
          _.get(obj, ['price'], 0) * _.get(obj, ['quantity'], 0)
        )
      })
    )
  }

  static instantCheckoutSummary(price, quantity, donations) {
    const product = {
      quantity: quantity,
      total: price * quantity,
    }
    const shipping = product.quantity === 0 || product.quantity > 1 ? 0 : 0.55
    const total = product.total + donations + shipping
    return {
      product: product,
      donations: donations,
      shipping: shipping,
      total: total,
    }
  }
}

import React from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import { EMAIL, FONTS } from '../utils/core'
import Colors from '../utils/colors'
import Window from '../utils/window'
import SocialLinks from './social_links'

const footerStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    padding: theme.spacing(2.5),
    color: Colors.get('white'),
    backgroundColor: Colors.get('grey', 800),
    alignItems: 'center',
    fontFamily: 'karla',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    width: '100%',
  },
  mission: {
    flex: 1,
    display: 'flex',
    minWidth: 250,
    whiteSpace: 'break-spaces',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Rubik',
  },
  social: {
    minWidth: 250,
    padding: theme.spacing(1),
  },
  social_links: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  supplemental: {
    flex: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  socialCopywrite: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    width: '100%',
    alignContent: 'space-around',
  },
  legal: {
    flex: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& p': {
      fontSize: 'smaller',
      marginRight: theme.spacing(1.5),
      whiteSpace: 'nowrap',
    },
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Rubik',
  },
  copywrite: {
    fontSize: 'smaller',
    fontFamily: 'karla',
  },
}))

const links = {
  facebook: 'https://www.facebook.com/madetouplift/',
  instagram: 'https://www.instagram.com/madetouplift/',
  email: EMAIL,
}

export default function Footer() {
  const classes = footerStyle()

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.content}>
        <Grid className={classes.mission}>
          <Typography>
            We promote collective and sustained activism by empowering
            resource-strapped social causes. 100% of proceeds go directly to the
            cause!
          </Typography>
        </Grid>
        <br />
      </Grid>
      <Grid className={classes.supplemental}>
        <Grid className={classes.legal}>
          {/* <Typography
            className={classes.link}
            onClick={() => Window.navigate('/about')}
          >
            About Us
          </Typography> */}
          <Typography
            className={classes.link}
            onClick={() => Window.navigate('/terms')}
          >
            Terms of Service
          </Typography>
          <Typography
            className={classes.link}
            onClick={() => Window.navigate('/privacy')}
          >
            Privacy Policy
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.socialCopywrite}>
        <SocialLinks
          oneColor="white"
          className={classes.social_links}
          links={links}
        />
        <Typography className={classes.copywrite}>
          &#169; {moment().year()} by Made to Uplift LLC
        </Typography>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = {}
Footer.defaultProps = {}
